import axios from 'axios';

const DEVELOPMENT = document.location.host.includes('localhost') && false;

const server = () => {
  return [
    // 'https://api-demo.gastronaut.ai',
    'https://api.gastronaut.ai',
    'https://api.gastronaut.ai',
    'https://api-next.gastronaut.ai',
  ][Math.floor(Math.random() * 4)];
};
const liveServer = server() || 'https://api.gastronaut.ai';

export default axios.create({
  baseURL: DEVELOPMENT
    ? 'http://localhost:8000' // 'https://europe-west3-schillingroofbarhd.cloudfunctions.net/apiEndpoint'
    : liveServer,
  timeout: 60000,
  headers: { 'X-Custom-Header': 'dashboard', uid: window.uid || '' },
});

export const apiendpoint = axios.create({
  baseURL: 'https://apiendpointv02-pcpy3ifg3a-ey.a.run.app',
  timeout: 60000,
  headers: { 'X-Custom-Header': 'dashboard', uid: window.uid || '' },
});

export const googleServer = axios.create({
  baseURL:
    'https://europe-west3-schillingroofbarhd.cloudfunctions.net/apiEndpoint',
  timeout: 60000,
  headers: { 'X-Custom-Header': 'dashboard', uid: window.uid || '' },
});

export const mainServer = axios.create({
  baseURL: DEVELOPMENT
    ? 'http://localhost:8000' // 'https://europe-west3-schillingroofbarhd.cloudfunctions.net/apiEndpoint'
    : 'https://api.gastronaut.ai',
  timeout: 60000,
  headers: { 'X-Custom-Header': 'dashboard', uid: window.uid || '' },
});
