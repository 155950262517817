// import GuestStatistics from 'App/GuestBook/Screens/Statistics';
// import ForgotPasswordContainer from 'App/Auth/Screens/ForgotPassword';
import LoginContainer from 'App/Auth/Screens/Login';
import DashboardContainer from 'App/Dashboard/Screens/Dashboard';
import TablePlan from 'App/ReservationBook/Screens/TablePlan';
import ReservationList from 'App/ReservationBook/Screens/ReservationList';
import TimeTable from 'App/ReservationBook/Screens/TimeTable';
// import Feedback from 'App/Feedback/Screens/Feedback';
// import { default as TakeAwayAvailability } from 'App/TakeAway/Screens/MealAvailability';
// import Orders from 'App/TakeAway/Screens/Orders';

import LoadingScreen from 'Components/Atoms/LoadingScreen';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Box from 'Components/Atoms/Box';
import UnderConstruction from 'Components/Molecules/UnderConstruction';
import CalendarScreen from 'App/Calendar/Screens/CalendarScreen';
// import { default as Voucher } from 'App/Voucher/Screens/General';
import { StylesProvider, useMediaQuery } from '@material-ui/core';
// import InvitationScreen from 'Components/Organisms/InvitationScreen';
import AddToHomeModal from 'App/Mobile/Components/AddToHomeModal';
import LargeGroupList from 'App/ReservationBook/Screens/LargeGroupList';
import DragAndDropProvider from 'CustomHooks/useDragAndDrop';
// import MobileQuicklookContainer from 'App/Mobile/Screens/QuicklookPage';
// import MobileTablePlan from 'App/Mobile/Screens/Reservations/MobileTablePlan';
// import MinConsumptionsSettings from 'App/Settings/Screens/MinConsumptionsSettings/MinConsumptionsSettings';
// import CustomElements from 'App/Settings/Screens/CustomElements/CustomElements';

const ReactLazyWithSuspense = (factory: any) => (params: any) => {
  const Component = React.lazy(factory);

  return (
    <Suspense fallback={<LoadingScreen loading />}>
      <Component {...params} />
    </Suspense>
  );
};

const Voucher = ReactLazyWithSuspense(() => import('App/Voucher/Screens/V02'));

const VoucherPurchases = ReactLazyWithSuspense(
  () => import('App/Voucher/Screens/Purchases')
);

const VoucherTemplates = ReactLazyWithSuspense(
  () => import('App/Voucher/Screens/Templates')
);

const VoucherFulfillment = ReactLazyWithSuspense(
  () => import('App/Voucher/Screens/Fulfillment')
);

const GuestBook = ReactLazyWithSuspense(
  () => import('App/GuestBook/Screens/GuestBook') // import('App/GuestBook/Screens/GuestBookV02')
);

const ReservationGuestBook = ReactLazyWithSuspense(
  () => import('App/ReservationBook/Screens/GuestBook')
);

const Feedback = ReactLazyWithSuspense(
  () => import('App/Feedback/Screens/Feedback')
);

const Statistics = ReactLazyWithSuspense(
  () => import('App/ReservationBook/Screens/Statistics')
);

const ForgotPasswordContainer = ReactLazyWithSuspense(
  () => import('App/Auth/Screens/ForgotPassword')
);

const InvitationScreen = ReactLazyWithSuspense(
  () => import('Components/Organisms/InvitationScreen')
);

const Orders = ReactLazyWithSuspense(
  () => import('App/TakeAway/Screens/Orders')
);

const DigitalMenu = ReactLazyWithSuspense(
  () => import('App/DigitalMenu/Screens/DigitalMenu')
);

const GuestStatistics = ReactLazyWithSuspense(
  () => import('App/GuestBook/Screens/Statistics')
);

const TakeAwayAvailability = ReactLazyWithSuspense(
  () => import('App/TakeAway/Screens/MealAvailability')
);

const MobileQuicklookContainer = ReactLazyWithSuspense(
  () => import('App/Mobile/Screens/QuicklookPage')
);

const MobileTablePlan = ReactLazyWithSuspense(
  () => import('App/Mobile/Screens/Reservations/MobileTablePlan')
);

const Experiences = ReactLazyWithSuspense(
  () => import('App/Experiences/Screens/Main')
);

const MinConsumptionsSettings = ReactLazyWithSuspense(
  () =>
    import(
      'App/Settings/Screens/MinConsumptionsSettings/MinConsumptionsSettings'
    )
);

// const CalendarScreen = ReactLazyWithSuspense(
//   () => import('App/Calendar/Screens/CalendarScreen')
// );

const GeneralInformation = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/GeneralInformation')
);
const IdentityAndDesign = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/IdentityAndIntegration')
);
const UserSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/UserSettings')
);
const PaymentSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/PaymentSettings')
);
const IntegrationSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/Integrations')
);

const AppSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/Apps')
);
const EmailTemplates = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/EmailTemplates')
);
const ReservationSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/ReservationSettingsNew')
);
const FeedbackSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/FeedbackSettings')
);
const FAQSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/FAQ')
);
const ReservationIntegrationSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/ReservationIntegrationSettings')
);
const Menues = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/Menues')
);
const Meals = ReactLazyWithSuspense(() => import('App/Settings/Screens/Meals'));
const Categories = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/Categories')
);
const Options = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/Options')
);
const TakeAwaySettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/TakeAwaySettings')
);
const TakeAwayShifts = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/TakeAwayShifts')
);
const VoucherSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/VoucherSettings')
);
const FloorPlanSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/FloorPlanSettings')
);
const ImagesSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/ImagesSettings')
);
const ProfileSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/ProfileSettings')
);
const SignatureSettings = ReactLazyWithSuspense(
  () => import('./App/Settings/Screens/SignatureSettings/SignatureSettings')
);
const WebsiteSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/WebsiteSettings')
);
const WebsitePages = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/WebsitePages')
);
const WebsiteEvents = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/WebsiteEvents')
);
const NewRestaurant = ReactLazyWithSuspense(
  () => import('App/Onboarding/Screens/NewRestaurant')
);
const StatisticsQuotaSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/StatisticsQuotaSettings')
);
const PreviewContainer = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/Preview')
);
const GuestAndResaImport = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/GuestAndResaImport')
);
const ProductsLinkstreeSettingsContainer = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/ProductsLinksSettings')
);
const LinktreeSettingsContainer = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/LinktreeSettings')
);
const GuestOrderingGeneral = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/GuestOrderingGeneral')
);
const GuestOrderingPayment = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/GuestOrderingPayment')
);
const GuestOrderingCallWaiter = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/GuestOrderingCallWaiter')
);
const GuestOrderingStations = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/GuestOrderingStations')
);
const SiaCurrentDay = ReactLazyWithSuspense(
  () => import('App/Dashboard/Screens/SiaCurrentDay')
);
const SettingsHome = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/SettingsHome')
);
const LightReservation = ReactLazyWithSuspense(
  () => import('App/ReservationBook/Screens/LightReservation')
);
const RouteManager = ReactLazyWithSuspense(
  () => import('App/TakeAway/Screens/RouteManager')
);
const NewOrder = ReactLazyWithSuspense(
  () => import('App/TakeAway/Screens/NewOrder')
);
const Accounting = ReactLazyWithSuspense(
  () => import('App/TakeAway/Screens/Accounting')
);
const EntranceTicket = ReactLazyWithSuspense(
  () => import('App/Mobile/Screens/EntranceTicket')
);
const TicketSalesOverview = ReactLazyWithSuspense(
  () => import('App/Tickets/TicketSalesOverview')
);
const Newsletter = ReactLazyWithSuspense(
  () => import('App/Newsletter/Screens/Main')
);

const NewsletterDraft = ReactLazyWithSuspense(
  () => import('App/Newsletter/Screens/Draft')
);

const AppStore = ReactLazyWithSuspense(
  () => import('App/Apps/Screens/General')
);

const SiaSettingsScreen = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/SiaSettings')
);

const CustomElementSettings = ReactLazyWithSuspense(
  () => import('App/Settings/Screens/CustomElements')
);

const EmptyRoute = () => {
  return (
    <Box style={{ width: '100vw', height: '100vh' }} flex className="center">
      <UnderConstruction />
    </Box>
  );
};

const Router = () => {
  const isMobile = useMediaQuery('(max-width:450px)');

  // if (isMobile) {
  //   return <InvitationScreen />;
  // }

  return (
    <StylesProvider injectFirst>
      <DragAndDropProvider>
        <>
          <AddToHomeModal />
          <Switch>
            <Suspense fallback={<LoadingScreen loading />}>
              <Route
                path="/m/quicklook"
                component={MobileQuicklookContainer}
                exact
              />

              <Route path="/" component={DashboardContainer} exact />
              <Route path="/auth/login" component={LoginContainer} exact />
              <Route
                path="/auth/forgot-password"
                component={ForgotPasswordContainer}
                exact
              />
              <Route
                path="/onboarding/:templateId/:sessionId"
                component={NewRestaurant}
                exact
              />
              <Route
                path="/:restaurantId"
                component={DashboardContainer}
                exact
              />
              <Route
                path="/:restaurantId/entranceTicket"
                component={EntranceTicket}
                exact
              />
              <Route
                path="/:restaurantId/calendar/:date"
                component={CalendarScreen}
                exact
              />
              <Route
                path="/:restaurantId/sia"
                component={SiaCurrentDay}
                exact
              />

              <Route path="/:restaurantId/apps" component={AppStore} exact />

              <Route
                path="/:restaurantId/reservations/:date/tablePlan"
                component={isMobile ? MobileTablePlan : TablePlan}
                exact
              />
              {/* ReservationList */}
              <Route
                path="/:restaurantId/reservations/:date/list"
                component={ReservationList}
                exact
              />
              <Route
                path="/:restaurantId/reservations/:date/reservationList"
                component={LightReservation}
                exact
              />
              <Route
                path="/:restaurantId/reservations/:date/timeTable"
                component={TimeTable}
                exact
              />
              <Route
                path="/:restaurantId/reservations/:date/statistics"
                component={Statistics}
                exact
              />
              <Route
                path="/:restaurantId/reservations/largeGroups"
                component={LargeGroupList}
                exact
              />
              <Route
                path="/:restaurantId/experiences"
                component={Experiences}
                exact
              />

              <Route
                path="/:restaurantId/newsletters"
                component={Newsletter}
                exact
              />

              <Route
                path="/:restaurantId/newsletters/draft/:newsletterId"
                component={NewsletterDraft}
                exact
              />

              <Route
                path="/:restaurantId/newsletters/sent/:newsletterId"
                component={Newsletter}
                exact
              />

              <Route
                path="/:restaurantId/reservations/:date/guestbook"
                component={ReservationGuestBook}
                exact
              />
              <Route
                path="/:restaurantId/reservations/:date/waitingList"
                component={EmptyRoute}
                exact
              />
              <Route
                path="/:restaurantId/guestbook"
                component={GuestBook}
                exact
              />
              <Route
                path="/:restaurantId/statistics"
                component={GuestStatistics}
                exact
              />
              <Route
                path="/:restaurantId/platform-preview"
                component={PreviewContainer}
                exact
              />
              <Route
                path="/:restaurantId/takeAway/orders/:date"
                component={Orders}
                exact
              />
              <Route
                path="/:restaurantId/takeAway/routeManager/:date"
                component={RouteManager}
                exact
              />
              <Route
                path="/:restaurantId/takeAway/accounting/:date"
                component={Accounting}
                exact
              />
              <Route
                path="/:restaurantId/takeAway/statistics/:date"
                component={EmptyRoute}
                exact
              />
              <Route
                path="/:restaurantId/takeAway/meal-availability/:date"
                component={TakeAwayAvailability}
                exact
              />
              <Route
                path="/:restaurantId/takeAway/newOrder"
                component={NewOrder}
                exact
              />
              <Route path="/:restaurantId/menu" component={DigitalMenu} exact />
              <Route
                path="/:restaurantId/feedback"
                component={Feedback}
                exact
              />
              <Route path="/:restaurantId/vouchers" component={Voucher} exact />
              <Route
                path="/:restaurantId/vouchers/purchases"
                component={VoucherPurchases}
                exact
              />
              <Route
                path="/:restaurantId/vouchers/templates"
                component={VoucherTemplates}
                exact
              />
              <Route
                path="/:restaurantId/vouchers/fulfillment"
                component={VoucherFulfillment}
                exact
              />
              <Route
                path="/:restaurantId/merchandise"
                component={EmptyRoute}
                exact
              />
              {/* </Suspense>
            Settings Routes 
          <Suspense fallback={<LoadingScreen loading />}> */}
              <Route
                path="/:restaurantId/settings"
                component={isMobile ? InvitationScreen : SettingsHome}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/general-information"
                component={GeneralInformation}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/identity"
                component={IdentityAndDesign}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/users"
                component={UserSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/signatures"
                component={SignatureSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/payments"
                component={PaymentSettings}
                exact
              />
              {/* <Route
              path="/:restaurantId/settings/general/integrations"
              component={IntegrationSettings}
              exact
            /> */}
              <Route
                path="/:restaurantId/settings/general/apps"
                component={AppSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/email-templates"
                component={EmailTemplates}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/images"
                component={ImagesSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/profile-settings"
                component={ProfileSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/sia"
                component={SiaSettingsScreen}
                exact
              />
              {/* <Route
              path="/:restaurantId/settings/reservations/sms-notifications"
              component={SmsSettingsContainer}
              exact
            /> */}
              <Route
                path="/:restaurantId/settings/reservations/reservation-settings"
                component={ReservationSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/reservations/statistics"
                component={StatisticsQuotaSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/reservations/table-plans"
                component={FloorPlanSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/reservations/feedback"
                component={FeedbackSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/general/faqs"
                component={FAQSettings}
                exact
              />

              <Route
                path="/:restaurantId/settings/reservations/integration"
                component={ReservationIntegrationSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/reservations/import-guest"
                component={GuestAndResaImport}
                exact
              />
              {/* <Route
              path="/:restaurantId/settings/reservations/min-consumptions"
              component={MinConsumptionsSettings}
              exact
            /> */}
              <Route
                path="/:restaurantId/settings/reservations/customElements"
                component={CustomElementSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/meals/menues"
                component={Menues}
                exact
              />
              <Route
                path="/:restaurantId/settings/meals/items-categories"
                component={Meals}
                exact
              />
              {/* <Route
              path="/:restaurantId/settings/meals/categories"
              component={Categories}
              exact
            /> */}
              {/* <Route
              path="/:restaurantId/settings/meals/options"
              component={Options}
              exact
            /> */}
              <Route
                path="/:restaurantId/settings/delivery/takeaway-settings"
                component={TakeAwaySettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/delivery/opening"
                component={TakeAwayShifts}
                exact
              />
              <Route
                path="/:restaurantId/settings/guest-ordering/general"
                component={GuestOrderingGeneral}
                exact
              />
              <Route
                path="/:restaurantId/settings/guest-ordering/payment"
                component={GuestOrderingPayment}
                exact
              />
              <Route
                path="/:restaurantId/settings/guest-ordering/call-waiter"
                component={GuestOrderingCallWaiter}
                exact
              />
              <Route
                path="/:restaurantId/settings/guest-ordering/stations"
                component={GuestOrderingStations}
                exact
              />
              <Route
                path="/:restaurantId/settings/vouchers/settings"
                component={VoucherSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/links/products-links"
                component={ProductsLinkstreeSettingsContainer}
                exact
              />
              <Route
                path="/:restaurantId/settings/links/linktree"
                component={LinktreeSettingsContainer}
                exact
              />
              <Route
                path="/:restaurantId/settings/website/website-settings"
                component={WebsiteSettings}
                exact
              />
              <Route
                path="/:restaurantId/settings/website/pages"
                component={WebsitePages}
                exact
              />
              <Route
                path="/:restaurantId/settings/website/events"
                component={WebsiteEvents}
                exact
              />
              <Route
                path="/:restaurantId/accounting"
                component={EmptyRoute}
                exact
              />
              <Route
                path="/:restaurantId/calendar"
                component={EmptyRoute}
                exact
              />
              <Route
                path="/:restaurantId/tickets/:date/sales-overview"
                component={TicketSalesOverview}
                exact
              />
            </Suspense>
          </Switch>
        </>
      </DragAndDropProvider>
    </StylesProvider>
  );
};

export default Router;
