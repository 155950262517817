import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import { ArrowBack, Delete } from 'Components/Atoms/Icons';
import React, { useEffect, useMemo, useState } from 'react';
import { EditReservation, Guest } from 'types/reservations';
import GuestDropdown from '../../GuestDropdown';
// import GuestDropdown from '../../GuestDropdownV02';
import TextField from 'Components/Atoms/TextField';
import AttributeDropdown from '../../AttributeDropdown';
import Typography from 'Components/Atoms/Typography';
import useGuestAutoComplete from 'CustomHooks/useGuestAutoComplete';
import Checkbox from 'Components/Atoms/Checkbox';
import usePhoneNumberValidator from 'CustomHooks/usePhoneNumberValidator';
import useSiaContext from 'CustomHooks/useSiaContext';
import CallListItem from 'Components/Organisms/SiaModal/Components/CallListItem';
import { ExtendedCall } from 'CustomHooks/useCalls';
import server from 'utils/server';
import useRestaurant from 'CustomHooks/useRestaurant';
import { toEuropeanDate } from 'utils/helper';
import { Trans } from 'react-i18next';
import IconButton from 'Components/Atoms/IconButton';
import CustomElements from './CustomElements';
import CommentModal from 'Components/Molecules/CommentModal';

type SecondStepProps = {
  reservation: EditReservation;
  onReservationChange: React.Dispatch<
    React.SetStateAction<EditReservation | null>
  >;
  onSave: () => Promise<void>;
};

type reservationFields =
  | 'guest'
  | 'phone'
  | 'email'
  | 'comment'
  | 'hostComment'
  | 'guestComment'
  | 'attr'
  | 'page'
  | 'sendEmail'
  | 'company'
  | 'preferredLanguage';

type newValue = string | string[] | number | undefined | Guest | boolean;

const EMPTY_GUEST: Guest = {
  name: '',
  guestId: '',
  phone: '',
  email: '',
  hostComment: '',
  comment: '',
  attr: [],
  phoneNumbers: [],
};

const useNoShowFee = (reservation: EditReservation) => {
  const { restaurantId } = useRestaurant();

  const [noShowFee, setNoShowFee] = useState<{
    skip: boolean;
    client_secret?: string;
    customer_id?: string;
    restaurantId?: string;
    reservationId?: string;
    publishable_key?: string;
    link?: string;
    disclaimer?: Record<string, string>;
    innerDisclaimer?: string;
  } | null>(null);

  useEffect(() => {
    server
      .get<{
        skip: boolean;
        client_secret?: string;
        customer_id?: string;
        restaurantId?: string;
        reservationId?: string;
        publishable_key?: string;
        link?: string;
        disclaimer?: Record<string, string>;
        innerDisclaimer?: string;
      }>(
        `/v03/reservations/checkNoShow/${restaurantId}/${reservation.reservationId}`
      )
      .then(({ data }) => {
        setNoShowFee(data);
      })
      .catch((err) => setNoShowFee(null));
  }, [reservation.reservationId, restaurantId]);

  return noShowFee;
};

const SecondStep = ({
  onReservationChange,
  reservation,
  onSave,
}: SecondStepProps) => {
  const phoneValidator = usePhoneNumberValidator((number) =>
    handleChange(number, 'phone')
  );

  const { reservationSettings, experimentalFlags } = useRestaurant();

  const { hasSia, calls } = useSiaContext();

  const handleChange = (newValue: newValue, type: reservationFields) => {
    const newReservation: EditReservation = { ...reservation };
    if (type === 'page') {
      newReservation[type] = newValue as any;
    } else if (type === 'guest') {
      newReservation[type] = newValue as Exclude<
        keyof EditReservation,
        newValue
      >;

      if (newReservation.guest?.email) {
        newReservation.guest.sendEmail = true;
      }

      phoneValidator.validatePhoneNumber((newValue as any)?.phone);
    } else {
      const newGuest: Guest = reservation.guest
        ? { ...reservation.guest }
        : EMPTY_GUEST;
      newGuest[type] = newValue as Exclude<keyof Guest, newValue>;

      if (type === 'email' && newValue) {
        newReservation.sendEmail = true;
        newGuest.sendEmail = true;
      }

      newReservation.guest = { ...newGuest };
    }
    onReservationChange(newReservation);
  };

  const activeCalls = useMemo(() => {
    return calls.filter((c) => c.createdAt > Date.now() - 20 * 60000);
  }, [calls]);

  const handleCallClick = (call: ExtendedCall) =>
    onReservationChange((old) => {
      if (!old) return null;

      if (call.guest) {
        const { name, email, phone, attr = [], ...guest } = call.guest;

        return {
          ...old,
          guest: {
            ...(guest as any),
            name,
            email,
            phone,
            attr,
            comment: old.guest?.comment || '',
            id: call.guest.guestId,
          },
        };
      } else {
        return {
          ...old,
          guest: {
            name: '',
            email: '',
            phone: call.callerId,
            attr: [],
            comment: old.guest?.comment || '',
            hostComment: '',
          },
        };
      }
    });

  const noShowFee = useNoShowFee(reservation);

  return (
    <Box className="new-reservation-sidebar">
      <Button
        startIcon={() => <ArrowBack style={{ marginRight: 8 }} />}
        fullWidth
        onClick={() => handleChange(0, 'page')}
        variant="outlined"
        style={{
          backgroundColor: 'var(--color-bg)',
        }}
      >
        Go Back
      </Button>
      {!!reservation.addToWaitinglist && (
        <Box style={{ color: 'var(--color-warning)', margin: '12px 0px' }}>
          <Typography
            variant="text-3"
            translation="reservations"
            block
            textAlign="center"
            color="inherit"
          >
            This is creating a Waitinglist Entry not a Reservation!
          </Typography>
        </Box>
      )}
      <Box className="second-step-field">
        <Box>
          <Box className="flex space-between" style={{ marginBottom: 4 }}>
            <Typography variant="text-3" translation="reservations" block>
              Date
            </Typography>
            <Typography
              variant="text-2"
              color="subdued"
              translation="reservations"
            >
              {toEuropeanDate(reservation.date)}{' '}
              {reservation.timeSlot?.time ?? reservation.time}
            </Typography>
          </Box>
          <Box className="flex space-between" style={{ marginBottom: 12 }}>
            <Typography variant="text-3" translation="reservations" block>
              <Trans i18nKey="reservations_Guests">Guests</Trans>{' '}
              {!reservation.addToWaitinglist && '& Tables'}
            </Typography>
            <Typography
              variant="text-2"
              color="subdued"
              translation="reservations"
            >
              {reservation.guests} PAX{' '}
              {(!reservation.addToWaitinglist &&
                `• ${reservation.tables?.join(', ')}`) ||
                ''}
            </Typography>
          </Box>
        </Box>

        {hasSia && !!activeCalls?.length && (
          <>
            <Typography style={{ marginBottom: 8 }} variant="text-4">
              Anrufe von SIA
            </Typography>
            <Box
              style={{
                maxHeight: 98,
                overflow: activeCalls.length > 1 ? 'scroll' : 'hidden',
                marginBottom: 20,
              }}
            >
              {activeCalls.map((call) => (
                <CallListItem
                  call={call}
                  key={call.id}
                  noStatus
                  active={
                    (call.guest?.phone || call.callerId) ===
                    reservation?.guest?.phone
                  }
                  onClick={() => handleCallClick(call)}
                />
              ))}
            </Box>
          </>
        )}
        <GuestDropdown
          value={reservation.guest}
          onChange={(guest) => handleChange(guest, 'guest')}
        />
      </Box>
      <Box className="second-step-field">
        <TextField
          value={reservation.guest?.phone || ''}
          onChange={(e) => handleChange(e.target.value, 'phone')}
          fullWidth
          required={!!reservationSettings?.phoneNumberMandatory}
          type="phone"
          defaultCountry={experimentalFlags?.data?.phoneDefaultCountry || 'de'}
          label="Phone Number"
          labelTranslation="reservations"
          {...phoneValidator.hook}
        />

        {!!reservation.guest?.phoneNumbers &&
          reservation.guest.phoneNumbers.map((number, i) => (
            <Box className="space-between" key={i}>
              <TextField
                value={number || ''}
                onChange={(e) =>
                  onReservationChange({
                    ...reservation,
                    guest: {
                      ...(reservation.guest ?? EMPTY_GUEST),
                      phoneNumbers:
                        reservation.guest?.phoneNumbers?.map((g, i1) =>
                          i === i1 ? e.target.value : g
                        ) ?? [],
                    },
                  })
                }
                fullWidth
                required
                type="phone"
                defaultCountry={
                  experimentalFlags?.data?.phoneDefaultCountry || 'de'
                }
                label={'Alternative Telefonnummer ' + (i + 1)}
                labelTranslation="reservations"
              />
              <IconButton
                size="small"
                color="subdued"
                onClick={() => {
                  onReservationChange({
                    ...reservation,
                    guest: {
                      ...(reservation.guest ?? EMPTY_GUEST),
                      phoneNumbers:
                        reservation.guest?.phoneNumbers?.filter(
                          (x, i2) => i2 !== i
                        ) ?? [],
                    },
                  });
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          ))}

        <Button
          variant="transparent"
          color="primary"
          fullWidth
          onClick={() =>
            onReservationChange({
              ...reservation,
              guest: {
                ...(reservation.guest ?? EMPTY_GUEST),
                phoneNumbers: [...(reservation.guest?.phoneNumbers ?? []), ''],
              },
            })
          }
        >
          Neue Alternative Telefonnr.
        </Button>

        {!!noShowFee?.customer_id && !reservation.addToWaitinglist && (
          <>
            <Checkbox
              checked={!!reservation.sendNoShowSMS}
              onChange={(_, sendNoShowSMS) =>
                onReservationChange((old) =>
                  !old ? null : { ...old, sendNoShowSMS }
                )
              }
              label="No Show SMS versenden"
              translation="reservations"
              style={{ marginLeft: -12 }}
            />
            <Box style={{ maxHeight: 120, overflow: 'scroll' }}>
              <Typography variant="text-4" color="subdued">
                {noShowFee.innerDisclaimer ||
                  noShowFee.disclaimer?.['de'] ||
                  ''}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box className="second-step-field">
        <TextField
          value={reservation.guest?.email || ''}
          onChange={(e) => handleChange(e.target.value, 'email')}
          fullWidth
          label="Email"
          required={!!reservationSettings?.emailMandatory}
          labelTranslation="reservations"
        />
        {!!reservation.guest?.email && (
          <Checkbox
            checked={!!reservation.guest.sendEmail}
            onChange={(_, sendEmail) => handleChange(sendEmail, 'sendEmail')}
            label="Guest agreed to receiving an Email"
            translation="reservations"
          />
        )}
      </Box>

      <Box className="second-step-field">
        <TextField
          value={reservation.guest?.company || ''}
          onChange={(e) => handleChange(e.target.value, 'company')}
          fullWidth
          label="Company"
          labelTranslation="reservations"
        />
      </Box>

      <Box className="second-step-field">
        <CommentModal
          value={reservation.guest?.comment || ''}
          onChange={(e) => handleChange(e, 'comment')}
          fullWidth
          label="Comment (Guest)"
          labelTranslation="reservations"
        />
      </Box>
      <Box className="second-step-field">
        <CommentModal
          value={reservation.guest?.hostComment || ''}
          onChange={(e) => handleChange(e, 'hostComment')}
          fullWidth
          label="Comment (Host)"
          labelTranslation="reservations"
        />
      </Box>
      <Box className="second-step-field">
        <CommentModal
          value={reservation.guest?.guestComment || ''}
          onChange={(e) => handleChange(e, 'guestComment')}
          fullWidth
          label="Langzeit Kommentar"
          labelTranslation="reservations"
        />
      </Box>
      <Box className="second-step-field">
        <Typography variant="text-3" translation="reservations">
          Attributes
        </Typography>
        <AttributeDropdown
          value={reservation.guest?.attr || []}
          onChange={(result) => handleChange(result, 'attr')}
        />
      </Box>
      <Checkbox
        checked={(reservation.guest?.preferredLanguage ?? 'de') !== 'de'}
        onChange={(e, checked) =>
          handleChange(checked ? 'en' : 'de', 'preferredLanguage')
        }
        label="Gast spricht Englisch"
        translation="reservations"
        style={{ marginLeft: -12 }}
      />
      <CustomElements
        id={reservation.reservationId ?? ''}
        customData={reservation.customData ?? {}}
        onCustomDataUpdate={(customData, complete) => {
          onReservationChange({
            ...reservation,
            customData: complete
              ? customData
              : { ...(reservation.customData ?? {}), ...customData },
          });
        }}
      />
    </Box>
  );
};

export default SecondStep;
